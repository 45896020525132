<template>
  <div>
    <div class="heade boxHead">
      <div class="box boxPhone">
        <div @click="backIndex" class="phoneWITD">
          <img
            src="../images/logo001.png"
            width="100px"
            style="float: left; margin-top: 27px"
          />
          <span
            class="decADD"
            style="
              float: left;

              margin-left: 20px;
              font-weight: bold;
              font-size: 18px;
              margin-top: 26px;
            "
          >
            新形态数字教材
          </span>
          <!-- <ul class="nav-li">
        <li @click="goToLink('./kc')">在线课程</li>
        <li @click="goToLink('./more_list')">课程速递</li>
      </ul> -->
          <div class="goAdd">
            <el-button
              type="success"
              plain
              size="mini"
              @click="jxFun"
              icon="el-icon-thumb"
              >在线开课</el-button
            >
          </div>
        </div>

        <!-- <div class="searchAdd" >
        <el-input
          placeholder="书名、作者"
          v-model="input3"
          class="input-with-select"
          size="small"
        >
          <el-button
            slot="append"
            icon="el-icon-search"
            size="small"
            @click="searchFun"
          ></el-button>
        </el-input>
      </div> -->

        <div class="addD">
          <el-button type="primary" @click="loginFun" size="mini" v-if="!login"
            >登录</el-button
          >

          <p v-else>
            <el-dropdown @command="handleCommand">
              <span class="el-dropdown-link">
                欢迎回来！ {{ name }}
                <i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item icon="el-icon-user-solid" command="身份认证"
                  >身份认证</el-dropdown-item
                >
                <el-dropdown-item
                  icon="el-icon-help"
                  command="作者入口"
                  v-if="rolesUN"
                  >作者管理入口</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>

            <el-button
              type="primary"
              @click="outFun"
              size="mini"
              style="margin-left: 15px"
              >登出</el-button
            >
          </p>
        </div>
      </div>
      <el-dialog
        class="vis"
        append-to-body
        title="在线开课"
        :visible.sync="dialogVisible"
        width="70%"
      >
        <!-- <p style="font-weight:bold;font-size:16px;margin-bottom:20px;text-align: right;">架设在公有云的希冀平台，免费使用。目前仅支持商业用户或者准商业用户本地部署。</p> -->
        <p
          style="
            font-size: 16px;
            font-weight: bold;
            color: #d9232d;
            cursor: pointer;
            margin-bottom: 15px;
          "
          @click="goTo('https://course.educg.net/')"
        >
          <i class="el-icon-s-platform" style="margin-right: 10px"></i>教学平台
          【 <span style="color: #409eff">course.educg.net</span> 】<i
            class="el-icon-top-right"
          ></i>
        </p>
        <p style="color: #31708f; padding-left: 30px">
          一站式在线教学与实验平台，涵盖人工智能、大数据、系统能力培养、在线考试、比赛等，详情参考在线课程首页下方的信息。
        </p>
        <el-divider></el-divider>
        <!--- @click="goTo('https://devms.t.educg.com/#/zl')"-->
        <p
          style="
            font-size: 16px;
            font-weight: bold;
            color: #d9232d;

            margin-bottom: 15px;
          "
        >
          <i class="el-icon-reading" style="margin-right: 10px"></i>在线开课（
          <span>仅限教师</span> ）
        </p>
        <p style="width: 200px; text-align: center; padding-left: 20px">
          <span style="color: green; font-weight: bold">微信扫一扫 </span>
        </p>
        <div class="g-container" style="margin-left: 20px">
          <img src="../images/WechatIMG7232.png" width="200px" />
        </div>

        <!-- <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false"
          >确 定</el-button
        >
      </span> -->
      </el-dialog>
    </div>
    <div style="height: 100px; width: 100%"></div>
  </div>
</template>

<script>
export default {
  name: "HelloWorld",
  props: {
    msg: String,
    // rolesUN: Boolean,
  },
  data() {
    return {
      dialogVisible: false,
      width: "41%",
      widthO: "27%",
      margintOPO: "25px",
      margintOPOj: "25px",
      widthD: "20%",
      input3: "",
      login: false,
      name: "",
      role: "student",
      uid: "",
      rolesUN: false,
      typeUrl: "pc",
      widthP: "10%",
      windowWidtha: "",
    };
  },
  mounted() {
    let windowWidth =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth;
    this.windowWidtha =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth;
    console.log(windowWidth);
    if (windowWidth < 880) {
      //移动端
      this.width = "100%";
      this.widthO = "59%";
      this.widthP = "37%";
      this.margintOPO = "25px";
      this.margintOPOj = "-25px";
      this.widthD = "27%";
      this.typeUrl = "phone";
    } else {
      this.width = "41%";
      this.widthP = "10%";
      this.widthO = "27%";
      this.margintOPO = "25px";
      this.margintOPOj = "25px";
      this.widthD = "20%";
      this.typeUrl = "pc";
    }
    console.log(this.typeUrl);
    debugger;
    // if (window.location.href.indexOf("type=out") != -1) {
    //   window.localStorage.setItem("anjing_name_ebook", "");
    //   window.localStorage.setItem("anjing_name_token", "");
    //   window.localStorage.setItem("anjing_name_uid", "");
    //   window.localStorage.setItem("anjing_name_role", "");
    //   window.localStorage.setItem("anjing_name_username", "");

    // }
    if (window.location.href.indexOf("?token=") != -1) {
      window.localStorage.setItem(
        "anjing_name_token",
        this.getQueryString("token")
      );
      this.tokenFun();
    }
    if (window.location.href.indexOf("&token=") != -1) {
      window.localStorage.setItem("anjing_name_token", this.$route.query.token);
      this.tokenFun();
    }

    if (
      window.localStorage.getItem("anjing_name_token") &&
      window.localStorage.getItem("anjing_name_token") != ""
    ) {
      this.tokenFun();
    }
    debugger;

    // if (
    //   // window.location.href.indexOf("name=") != -1 &&
    //   // window.location.href.indexOf("&id=") != -1 &&
    //   window.location.href.indexOf("?token=") != -1 &&
    //   window.location.href.indexOf("type=out") == -1
    // ) {
    //   // 已登录
    //   this.login = true;

    //     this.init();
    //   if (
    //     window.localStorage.getItem("anjing_name_token") &&
    //     window.localStorage.getItem("anjing_name_token") != "" &&
    //     window.localStorage.getItem("anjing_name_token") != "undefined"
    //   ) {
    //     this.role = window.localStorage.getItem("anjing_name_role");
    //     // window.location.href = response.data.data;
    //     this.name = window.localStorage.getItem("anjing_name_ebook");
    //     this.uid = window.localStorage.getItem("anjing_name_uid");
    //     console.log(6)
    //     this.init();
    //   } else {
    //     window.localStorage.setItem(
    //       "anjing_name_token",
    //       this.getQueryString("token")
    //     );
    //     this.tokenFun();

    //   }

    //   // this.name = window.localStorage.getItem("anjing_name_ebook");
    // } else {
    //   debugger
    //   if (
    //     window.localStorage.getItem("anjing_name_ebook") &&
    //     window.localStorage.getItem("anjing_name_ebook") != "" &&
    //     window.localStorage.getItem("anjing_name_ebook") != "undefined"
    //   ) {
    //     // this.name = window.localStorage.getItem("anjing_name_ebook");
    //     // this.login = true;
    //     // if(window.localStorage.getItem('anjing_rolesUN')){
    //     //   this.rolesUN = true;
    //     // }

    //   } else {
    //     this.login = false;
    //   }
    // }

    if (
      window.localStorage.getItem("anjing_input3_name") &&
      window.location.href.indexOf("search_list") != -1
    ) {
      this.input3 = window.localStorage.getItem("anjing_input3_name");
    } else {
      this.input3 = "";
    }
  },
  methods: {
    goToLink(url) {
      this.$router.push(url);
    },
    tokenFun() {
      let data = {
        op: "getuserinfo",
        token: window.localStorage.getItem("anjing_name_token"),
      };
      let _this = this;
      this.$ajax
        .post(this.url + "/index.do", _this.Qs.stringify(data))
        .then(function (response) {
          console.log(response);
          if (response.data.value == "ok") {
            _this.role = response.data.data.role;
            // window.location.href = response.data.data;
            _this.name = response.data.data.name;
            _this.uid = response.data.data.uid;
            console.log('response.data.data.uid',response.data.data.uid)
            window.localStorage.setItem("anjing_name_uid", _this.uid);
            window.localStorage.setItem("anjing_name_role", _this.role);
            window.localStorage.setItem(
              "anjing_name_ebook",
              _this.name
              // this.getQueryString("token")
            );
            _this.name = window.localStorage.getItem("anjing_name_ebook");
            _this.login = true;
            window.localStorage.setItem(
              "anjing_name_username",
              response.data.data.username
              // this.getQueryString("token")
            );

            _this.init();
          } else if (response.data.value == "失效") {
            _this.$message.info("登录已失效，请重新登录！");
            _this.login = false;
            window.localStorage.setItem("anjing_name_ebook", "");
            window.localStorage.setItem("anjing_name_token", "");
            window.localStorage.setItem("anjing_name_uid", "");
            window.localStorage.setItem("anjing_name_role", "");
            window.localStorage.setItem("anjing_name_username", "");
          } else {
            _this.login = false;
            window.localStorage.setItem("anjing_name_ebook", "");
            window.localStorage.setItem("anjing_name_token", "");
            window.localStorage.setItem("anjing_name_uid", "");
            window.localStorage.setItem("anjing_name_role", "");
            window.localStorage.setItem("anjing_name_username", "");
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    getParams(url, params) {
      var res = new RegExp("(?:&|/?)" + params + "=([^&$]+)").exec(url);
      return res ? res[1] : "";
    },

    init() {
      this.subD = true;
      let data = {
        op: "getauthinfo",
        uid: window.localStorage.getItem("anjing_name_uid"), //用户编号
        uid: window.localStorage.getItem("anjing_name_uid"), //用户编号
      };
      let _this = this;
      this.$ajax
        .post(this.url + "/index.do", _this.Qs.stringify(data))
        .then(function (response) {
          console.log(response);
          if (response.data.value == "ok") {
            if (
              response.data.data.check == "审核通过" &&
              response.data.data.group == "teacher"
            ) {
              _this.rolesUN = true;
              window.localStorage.setItem("anjing_rolesUN", _this.rolesUN);
              _this.rolesUN = true;
            }
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    handleCommand(command) {
      console.log(command);
      if (command == "身份认证") {
        // this.$router.push({ path: "./teuserSolid" });
        window.location.href = this.url + "#/teuserSolid";
      }
      if (command == "作者入口") {
        window.location.href = this.url + "/adminWeb/index.html";
      }
    },
    backIndex() {
      // this.$router.push({ path: "./" });
      this.$router.go(0);
    },
    outFun() {
      let data = {
        op: "logout",
        token: window.localStorage.getItem("anjing_name_token"),
      };
      let _this = this;
      this.$ajax
        .post(this.url + "/index.do", _this.Qs.stringify(data))
        .then(function (response) {
          console.log(response);
          if (response.data.value == "ok") {
            window.location.href = response.data.data;
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    getQueryString(name) {
      debugger;
      var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
      var r = window.location.search.substr(1).match(reg);
      if (r != null) {
        return unescape(r[2]);
      }
      return null;
      // function getParams(url, params){
      // var res = new RegExp("(?:&|/?)" + params + "=([^&$]+)").exec(url);
      // return res ? res[1] : '';
      // }
    },

    searchFun() {
      // let data = {
      //   op: "search",
      //   page:1,
      //   limit:10,
      //   name:this.input3

      // };
      // let _this = this;
      // this.$ajax
      //   .post(this.url + "/index.do", _this.Qs.stringify(data))
      //   .then(function (response) {
      //     console.log(response);
      //     if (response.data.value == "ok") {

      //     }
      //   })
      //   .catch(function (error) {
      //     console.log(error);
      //   });
      window.localStorage.setItem("anjing_input3_name", this.input3);
      if (window.location.href.indexOf("search_list") != -1) {
        this.$emit("searchFun", "0");
      } else {
        this.$router.push({ path: "./search_list" });
      }
    },
    jxFun(event) {
      event.stopPropagation();

      // 阻止事件的默认行为
      event.preventDefault();
      // window.open(" https://xuezhiqiao.educg.net/course.jsp");
      this.dialogVisible = true;
    },
    loginFun() {
      debugger;
      let data = {
        op: "login",
        type: "",
        key: "",
        value: "",
      };
      let _this = this;
      this.$ajax
        .post(this.url + "/index.do", _this.Qs.stringify(data))
        .then(function (response) {
          console.log(response);
          if (response.data.value == "ok") {
            window.location.href = response.data.data;
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    goTo(url) {
      window.open(url);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.heade {
  width: 100%;
  height: 80px;
  box-shadow: 1px 2px 3px 4px #ccc;
  background: #4e4e4e;
  color: #fff;
  margin-bottom: 20px;
  position: fixed;
  top: 0;
  z-index: 999;

  .box {
    max-width: 90%;
    margin: 0 auto;
    // min-width: 1000px;
    display: flex;
    justify-content: space-between;
  }

  .el-dropdown {
    color: #fff;
  }
}

.boxL {
}
.phoneWITD {
  // width: 41%;
}
.addD {
  // width: 20%;
  margin-top: 25px;
}
.addD .el-dropdown-link {
  color: #fff;
}
.searchAdd {
  margin-top: 25px;
  width: 27%;
}
.goAdd {
  margin-top: 25px;
  float: right;
  // width: 10%;
  // margin-right: 20px;
  display: inline-block;
  margin-left: 50px;
}
.vis .el-divider--horizontal {
  margin: 24px 0 !important;
}
.nav-li {
  visibility: hidden;
  overflow: auto;
  line-height: 80px;
  text-align: left;
  // width:55%;
  display: inline-block;
  margin-left: 30px;
  color: #8effb5;
}
.nav-li li {
  float: left;
  margin-right: 20px;
  cursor: pointer;
  font-weight: bold;
}
.nav-li li:hover {
  color: #00d049;
}
.g-container {
  position: relative;
  width: 200px;
  height: 200px;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: linear-gradient(45deg, #00cc3a, #03994a);
    mix-blend-mode: lighten;
  }
}
@media (max-width: 880px) {
  .boxHead {
    height: 120px;
  }
  .boxPhone {
    min-width: auto;
  }
  .phoneWITD {
    width: 100%;
  }
  .decADD,
  .nav-li {
    display: none;
  }
  .searchAdd {
    width: 64%;
  }
  .goAdd {
    // margin-top: 25px;
    // width: 36%;
    margin-top: 70px;
  }
  .addD {
    margin-top: 25px;
    position: absolute;
    right: 18px;
    top: 24px;
    margin-top: 0 !important;
    width: auto !important;
  }
  .heade .box {
    max-width: 90%;
    margin: 0 auto;
    min-width: auto;
  }
}
</style>
